import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { zeroPad } from '@/utils/util'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value, places = 0) {
  return zeroPad(places, '0')
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
