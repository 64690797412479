// eslint-disable-next-line
import { UserLayout, BasicLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const ROLES = {
  ROLE_MANAGEMENT_USER: 'ROLE_MANAGEMENT_USER',
  ROLE_PERMISSION_USER: 'ROLE_PERMISSION_USER',
  ROLE_PERMISSION_USER_PASSWORD: 'ROLE_PERMISSION_USER_PASSWORD',
  ROLE_PERMISSION_ROLE: 'ROLE_PERMISSION_ROLE',
  ROLE_PERMISSION_PERMISSION: 'ROLE_PERMISSION_PERMISSION',
  ROLE_PERMISSION_MAI2: 'ROLE_PERMISSION_MAI2',
  ROLE_PERMISSION_MAI2_DELETE_USER: 'ROLE_PERMISSION_MAI2_DELETE_USER',
  ROLE_PERMISSION_MAI2_IMPORT_USER: 'ROLE_PERMISSION_MAI2_IMPORT_USER',
  ROLE_PERMISSION_AIME: 'ROLE_PERMISSION_AIME',
  ROLE_PERMISSION_AIME_BAN: 'ROLE_PERMISSION_AIME_BAN',
  ROLE_PERMISSION_ARCADE: 'ROLE_PERMISSION_ARCADE',
  ROLE_PERMISSION_WEB: 'ROLE_PERMISSION_WEB',
  ROLE_PERMISSION_INVITE_CODE: 'ROLE_PERMISSION_INVITE_CODE',
  ROLE_PERMISSION_REDEEM_CODE: 'ROLE_PERMISSION_REDEEM_CODE'

}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/dashboard/workplace',
    children: [
      // dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: RouteView,
        meta: { title: 'menu.dashboard', keepAlive: true, icon: bxAnaalyse, permission: ['ROLE_ADMIN'] },
        children: [
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: 'menu.dashboard.workplace', keepAlive: true, icon: 'home', permission: ['ROLE_ADMIN'] }
          }
        ]
      },
      // Arcade
      {
        path: '/arcade',
        name: 'Arcade',
        component: RouteView,
        meta: { title: 'menu.arcade', keepAlive: true, icon: 'database', permission: [ROLES.ROLE_PERMISSION_ARCADE] },
        redirect: '/arcade/machine',
        children: [
          {
            path: '/arcade/config',
            name: 'Machine Global Config',
            component: () => import('@/views/arcade/ArcadeConfig'),
            meta: { title: 'menu.arcade.config', keepAlive: false, icon: 'setting', permission: [ROLES.ROLE_PERMISSION_ARCADE] }
          },
          {
            path: '/arcade/place',
            name: 'Place List',
            component: () => import('@/views/arcade/PlaceList'),
            meta: { title: 'menu.arcade.place.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_ARCADE] }
          },
          {
            path: '/arcade/machine',
            name: 'Machine List',
            component: () => import('@/views/arcade/MachineList'),
            meta: { title: 'menu.arcade.machine.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_ARCADE] }
          },
          {
            path: '/arcade/imageUpdate',
            name: 'Imagic Update List',
            component: () => import('@/views/arcade/ImagicUpdate'),
            meta: { title: 'menu.arcade.imagicUpdate.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_ARCADE] }
          }
        ]
      },
      // Web
      {
        path: '/web',
        name: 'Web',
        component: RouteView,
        meta: { title: 'menu.web', keepAlive: true, icon: 'database', permission: [ROLES.ROLE_PERMISSION_WEB] },
        redirect: '/web/invite',
        children: [
          {
            path: '/web/invite',
            name: 'Invite Group List',
            component: () => import('@/views/web/InviteCodeGroupList'),
            meta: { title: 'menu.web.invite.group.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_INVITE_CODE] }
          },
          {
            path: '/web/invite/:id',
            name: 'Invite Code List',
            hidden: true,
            component: () => import('@/views/web/InviteCodeList'),
            meta: { title: 'menu.web.invite.group.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_INVITE_CODE] }
          },
          {
            path: '/web/redeem',
            name: 'Redeem Group List',
            component: () => import('@/views/web/RedeemCodeGroupList'),
            meta: { title: 'menu.web.redeem.group.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_REDEEM_CODE] }
          },
          {
            path: '/web/redeem/:id',
            name: 'Redeem Code List',
            hidden: true,
            component: () => import('@/views/web/RedeemCodeList'),
            meta: { title: 'menu.web.redeem.group.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_REDEEM_CODE] }
          }
        ]
      },
      // Aime
      {
        path: '/aime',
        name: 'Aime',
        component: RouteView,
        meta: { title: 'menu.aime', keepAlive: true, icon: 'credit-card', permission: [ROLES.ROLE_PERMISSION_AIME] },
        children: [
          {
            path: '/aime',
            name: 'Aime List',
            component: () => import('@/views/aime/AimeList'),
            meta: { title: 'menu.aime.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_AIME] }
          },
          {
            path: '/aime/ban',
            name: 'Aime Ban List',
            component: () => import('@/views/aime/AimeBanList'),
            meta: { title: 'menu.aime.ban', keepAlive: false, icon: 'stop', permission: [ROLES.ROLE_PERMISSION_AIME_BAN] }
          }
        ]
      },
      // Mai2
      {
        path: '/game/mai2',
        name: 'Mai2',
        component: RouteView,
        meta: { title: 'menu.game.mai2', keepAlive: true, icon: 'setting', permission: [ROLES.ROLE_PERMISSION_MAI2] },
        redirect: '/game/mai2/player',
        children: [
          {
            path: '/game/mai2/player',
            name: 'Mai2 Player List',
            hideChildrenInMenu: true,
            component: RouteView,
            meta: { title: 'menu.game.mai2.player', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_MAI2] },
            children: [
              {
                path: '/game/mai2/player',
                name: 'Mai2 Player List',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerList'),
                meta: { title: 'menu.game.mai2.player', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:id',
                name: 'Mai2 Player Detail',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerDetail'),
                meta: { title: 'menu.game.mai2.playerDetail', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:playerId/card',
                name: 'Mai2 Player Card',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerCardList'),
                meta: { title: 'menu.game.mai2.playerCardList', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:playerId/character',
                name: 'Mai2 Player Character',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerCharacterList'),
                meta: { title: 'menu.game.mai2.playerCharacterList', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:playerId/item',
                name: 'Mai2 Player Item',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerItemList'),
                meta: { title: 'menu.game.mai2.playerItemList', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:playerId/photo',
                name: 'Mai2 Player Photo',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerPhotoList'),
                meta: { title: 'menu.game.mai2.playerPhotoList', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:playerId/music',
                name: 'Mai2 Player Music Detail',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerMusicDetailList'),
                meta: { title: 'menu.game.mai2.playerMusicDetailList', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              },
              {
                path: '/game/mai2/player/:playerId/playlog',
                name: 'Mai2 Player Playlog',
                hidden: true,
                component: () => import('@/views/game/mai2/PlayerPlaylogList'),
                meta: { title: 'menu.game.mai2.playerPlaylogList', keepAlive: false, permission: [ROLES.ROLE_PERMISSION_MAI2] }
              }
            ]
          },
          {
            path: '/game/mai2/dxPass',
            name: 'Mai2 Dx Pass',
            component: () => import('@/views/game/mai2/PlayerDxPass'),
            meta: { title: 'menu.game.mai2.addDxPass', keepAlive: false, icon: 'crown', permission: [ROLES.ROLE_PERMISSION_MAI2] }
          },
          {
            path: '/game/mai2/import',
            name: 'Mai2 Dx Player Import',
            component: () => import('@/views/game/mai2/PlayerImport'),
            meta: { title: 'menu.game.mai2.import', keepAlive: false, icon: 'cloud-upload', permission: [ROLES.ROLE_PERMISSION_MAI2_IMPORT_USER] }
          },
          {
            path: '/game/mai2/music',
            name: 'Mai2 Dx Game Music',
            component: () => import('@/views/game/mai2/GameMusic'),
            meta: { title: 'menu.game.mai2.music', keepAlive: false, icon: 'calendar', permission: [ROLES.ROLE_PERMISSION_MAI2_IMPORT_USER] }
          },
          {
            path: '/game/mai2/event',
            name: 'Mai2 Dx Game Event',
            component: () => import('@/views/game/mai2/GameEvent'),
            meta: { title: 'menu.game.mai2.event', keepAlive: false, icon: 'calendar', permission: [ROLES.ROLE_PERMISSION_MAI2_IMPORT_USER] }
          },
          {
            path: '/game/mai2/eventTemplate',
            name: 'Mai2 Dx Game Event Template',
            component: () => import('@/views/game/mai2/GameEventTemplate'),
            meta: { title: 'menu.game.mai2.eventTemplate', keepAlive: false, icon: 'carry-out', permission: [ROLES.ROLE_PERMISSION_MAI2_IMPORT_USER] }
          },
          {
            path: '/game/mai2/machineConfig',
            name: 'Mai2 Dx Game Machine Config',
            component: () => import('@/views/game/mai2/GameMachineConfig'),
            meta: { title: 'menu.game.mai2.machineConfig', keepAlive: false, icon: 'setting', permission: [ROLES.ROLE_PERMISSION_MAI2_IMPORT_USER] }
          }

        ]
      },
      // User
      {
        path: '/admin',
        name: 'admin',
        component: RouteView,
        meta: { title: 'menu.user', keepAlive: true, icon: 'team', permission: ['ROLE_ADMIN'] },
        children: [
          {
            path: '/admin/user',
            name: 'User List',
            component: () => import('@/views/management/user/UserList'),
            meta: { title: 'menu.user.list', keepAlive: false, icon: 'profile', permission: [ROLES.ROLE_PERMISSION_USER] }
          },
          {
            path: '/admin/role',
            name: 'Role List',
            component: () => import('@/views/management/role/RoleList'),
            meta: { title: 'menu.role.list', keepAlive: false, icon: 'solution', permission: [ROLES.ROLE_PERMISSION_ROLE] }
          },
          {
            path: '/admin/permission',
            name: 'Permission List',
            component: () => import('@/views/management/permission/PermissionList'),
            meta: { title: 'menu.permission.list', keepAlive: false, icon: 'audit', permission: [ROLES.ROLE_PERMISSION_PERMISSION] }
          }
        ]
      },
      // Demo
      // {
      //   path: '/demo',
      //   name: 'demo',
      //   component: RouteView,
      //   hideChildrenInMenu: true,
      //   meta: { title: '' },
      //   children: [
      //     {
      //       path: '/demo/profileBasic',
      //       name: 'profile basic',
      //       component: () => import('@/views/profile/basic/index'),
      //       meta: { title: 'Profile' }
      //     },
      //     {
      //       path: '/demo/profileAdvanced',
      //       name: 'profile advanced',
      //       component: () => import('@/views/profile/advanced/Advanced'),
      //       meta: { title: 'Profile' }
      //     }
      //   ]
      // },

      // Exception
      {
        path: '/exception',
        name: 'exception',
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
            meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
            meta: { title: 'menu.exception.not-find', permission: ['exception'] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
            meta: { title: 'menu.exception.server-error', permission: ['exception'] }
          }
        ]
      },

      // account
      {
        path: '/account',
        component: RouteView,
        redirect: '/account/center',
        name: 'account',
        hideChildrenInMenu: true,
        meta: { title: 'menu.account', icon: 'user', keepAlive: true, permission: ['ROLE_USER'] },
        children: [
          {
            path: '/account/center',
            name: 'center',
            component: () => import('@/views/account/center'),
            meta: { title: 'menu.account.center', keepAlive: true, permission: ['ROLE_USER'] }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
